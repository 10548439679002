<template>
    <div>
        <p class="text" v-for="text in texts" :key="text">
            {{ $t(text) }}
        </p>
        <div class="screenshot">
            <img class="step-image" src="../assets/screen-3.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            texts: ['faq.emby.step3.setupEPG', 'faq.emby.step3.sameLiveTV']
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
